// Composable for the current (logged in) user profile
import { v4 as uuidv4 } from 'uuid'

export const useUserProfile = () => {
  const { $log } = useNuxtApp()

  const profileStore = useProfileStore()
  const { currentUser, supabaseUser } = storeToRefs(profileStore)

  const appStore = useAppStore()
  const { deviceId: storeDeviceId } = storeToRefs(appStore)

  const supabase = useSupabaseClient()

  const refetchUser = ref(false)

  supabase.auth.onAuthStateChange((event, session) => {
    $log('composables:useUserProfile:onAuthStateChange:', event)

    if (event === 'INITIAL_SESSION') {
      // handle initial session
      if (session?.user) {
        profileStore.fetchSupabaseUser()
      } else {
        profileStore.clearUser()
      }
    } else if (event === 'SIGNED_IN') {
      // handle sign in event
      profileStore.fetchSupabaseUser()
      refetchUser.value = true
    } else if (event === 'SIGNED_OUT') {
      // handle sign out event
      profileStore.clearUser()
    } else if (event === 'PASSWORD_RECOVERY') {
      // handle password recovery event
    } else if (event === 'TOKEN_REFRESHED') {
      // handle token refreshed event
    } else if (event === 'USER_UPDATED') {
      // handle user updated event
      profileStore.fetchSupabaseUser()
    }
  })

  // if supabaseUser is not null or supabaseUser.value.id changes, then fetch the current user
  watch(
    supabaseUser,
    async (newValue) => {
      // $log(
      //   'composables:useUserProfile:watch:supabaseUser:oldValue',
      //   oldValue?.id
      // )
      // $log(
      //   'composables:useUserProfile:watch:supabaseUser:newValue',
      //   newValue?.id
      // )
      if (newValue && newValue.id) {
        $log('composables:useUserProfile:watch:supabaseUser:fetchCurrentUser')

        const deviceId = useCookie('deviceId', { maxAge: 60 * 60 * 24 * 90 })
        deviceId.value = deviceId.value || uuidv4()

        if (storeDeviceId.value === null) {
          storeDeviceId.value = deviceId.value
        }

        // based on: https://github.com/nuxt/nuxt/issues/13369#issuecomment-1397305253
        await Promise.all([
          profileStore.fetchCurrentUser(),
          profileStore.fetchProfiles(),
          profileStore.fetchShareProOrigin(),
        ])
      }
    },
    { immediate: true, deep: true }
  )
  watch(refetchUser, async (newValue, oldValue) => {
    $log('composables:useUserProfile:watch:refetchUser:oldValue', oldValue)
    $log('composables:useUserProfile:watch:refetchUser:newValue', newValue)
    if (newValue && !currentUser.value) {
      $log('composables:useUserProfile:watch:refetchUser:fetchCurrentUser')
      // based on:
      await Promise.all([
        profileStore.fetchCurrentUser(),
        profileStore.fetchProfiles(),
        profileStore.fetchShareProOrigin(),
      ])
      refetchUser.value = false
    }
  })
}
