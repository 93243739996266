import { v4 as uuidv4 } from 'uuid'

export const useMetricStore = defineStore('metric', {
  state: () => ({
    sessionId: null,
    metricKeys: [
      { key: 'APP_START', value: 'app_start' },
      { key: 'ERROR', value: 'error' },
      { key: 'FEED_ITEM_CLICK', value: 'feed_item_click' },
      { key: 'FEED_ITEM_VIEW', value: 'feed_item_view' },
      {
        key: 'ITEM_DETAIL_TRANSLATION_CLICK',
        value: 'item_detail_translation_click',
      },
      { key: 'MAP_ITEM_CLICK', value: 'map_item_click' },
      { key: 'PAGE_VIEW', value: 'page_view' },
      { key: 'REDIRECT', value: 'redirect' },
    ],
  }),
  actions: {
    async createMetric(metric) {
      const supabase = useSupabaseClient()
      const user = useSupabaseUser()

      if (this.sessionId === null) {
        this.sessionId = uuidv4()
      }

      const key = this.metricKeys.find(
        (metricKey) => metricKey.key === metric.key
      )
      if (!key) return

      const routeFullPath = useNuxtApp().$router.currentRoute.value.fullPath
      const routeBaseName = useNuxtApp().$getRouteBaseName()

      const newMetric = {
        session_id: this.sessionId,
        inserted_by: user.value ? user.value.id : null,
        key: key.value,
        value: metric.value ? metric.value : null,
        item_id: metric.itemId ? metric.itemId : null,
        item_detail_translation_id: metric.itemDetailTranslationId
          ? metric.itemDetailTranslationId
          : null,
        referrer: metric.referrer ? metric.referrer : null,
        quo_version: metric.quoVersion ? metric.quoVersion : null,
        quo_plattform: metric.quoPlattform ? metric.quoPlattform : null,
        plattform_language: metric.plattformLanguage
          ? metric.plattformLanguage
          : null,
        window_size: metric.windowSize ? metric.windowSize : null,
        user_agent: metric.userAgent ? metric.userAgent : null,
        route_full_path: routeFullPath,
        route_base_name: routeBaseName,
        is_desktop:
          metric.isDesktop === true || metric.isDesktop === false
            ? metric.isDesktop
            : null,
        is_mobile:
          metric.isMobile === true || metric.isMobile === false
            ? metric.isMobile
            : null,
        is_tablet:
          metric.isTablet === true || metric.isTablet === false
            ? metric.isTablet
            : null,
        is_ios:
          metric.isIos === true || metric.isIos === false ? metric.isIos : null,
        is_windows:
          metric.isWindows === true || metric.isWindows === false
            ? metric.isWindows
            : null,
        is_macos:
          metric.isMacOS === true || metric.isMacOS === false
            ? metric.isMacOS
            : null,
        is_android:
          metric.isAndroid === true || metric.isAndroid === false
            ? metric.isAndroid
            : null,
        is_firefox:
          metric.isFirefox === true || metric.isFirefox === false
            ? metric.isFirefox
            : null,
        is_edge:
          metric.isEdge === true || metric.isEdge === false
            ? metric.isEdge
            : null,
        is_chrome:
          metric.isChrome === true || metric.isChrome === false
            ? metric.isChrome
            : null,
        is_safari:
          metric.isSafari === true || metric.isSafari === false
            ? metric.isSafari
            : null,
        is_crawler:
          metric.isCrawler === true || metric.isCrawler === false
            ? metric.isCrawler
            : null,
        device_id: metric.deviceId ? metric.deviceId : null,
      }

      const { error } = await supabase.from('metrics').insert(newMetric)
      if (process.env.NODE_ENV === 'development' && error) {
        console.log('useMetricStore:createMetric:error: ', error)
      }
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useMetricStore, import.meta.hot))
}
