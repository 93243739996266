import { default as cancel5g99FWqUSVMeta } from "/tmp/build_a226154c/pages/auth/checkout/cancel.vue?macro=true";
import { default as choose_45productWj5pfi2XmcMeta } from "/tmp/build_a226154c/pages/auth/checkout/choose-product.vue?macro=true";
import { default as success_45quo_45proDXktB1MEW9Meta } from "/tmp/build_a226154c/pages/auth/checkout/success-quo-pro.vue?macro=true";
import { default as successo1ed61oyjWMeta } from "/tmp/build_a226154c/pages/auth/checkout/success.vue?macro=true";
import { default as share_45pro_45successsfjizDfKOiMeta } from "/tmp/build_a226154c/pages/auth/invite/share-pro-success.vue?macro=true";
import { default as share_45proy4UcNCuGQ7Meta } from "/tmp/build_a226154c/pages/auth/invite/share-pro.vue?macro=true";
import { default as confirm_45emailHoRFiSCl0aMeta } from "/tmp/build_a226154c/pages/auth/register/confirm-email.vue?macro=true";
import { default as create_45profileNMabNri1hTMeta } from "/tmp/build_a226154c/pages/auth/register/create-profile.vue?macro=true";
import { default as forward_45urlGQ9LObp9kgMeta } from "/tmp/build_a226154c/pages/auth/register/forward-url.vue?macro=true";
import { default as indexRQn7jzDG7eMeta } from "/tmp/build_a226154c/pages/auth/register/index.vue?macro=true";
import { default as indexvhs8yqwJ2lMeta } from "/tmp/build_a226154c/pages/auth/sign-in/index.vue?macro=true";
import { default as reset_45passwordhZ0RXXpjFoMeta } from "/tmp/build_a226154c/pages/auth/sign-in/reset-password.vue?macro=true";
import { default as send_45password_45link7hiNZmY6vfMeta } from "/tmp/build_a226154c/pages/auth/sign-in/send-password-link.vue?macro=true";
import { default as teamm49VGZKBRLMeta } from "/tmp/build_a226154c/pages/company/team.vue?macro=true";
import { default as confirmN1a3UrtS65Meta } from "/tmp/build_a226154c/pages/confirm.vue?macro=true";
import { default as index2E78Yvdw7qMeta } from "/tmp/build_a226154c/pages/dashboard/helper/countries/index.vue?macro=true";
import { default as _91_91id_93_93M7snwPiqULMeta } from "/tmp/build_a226154c/pages/dashboard/helper/tags/[[id]].vue?macro=true";
import { default as indexGIMyNz4NkeMeta } from "/tmp/build_a226154c/pages/dashboard/index.vue?macro=true";
import { default as _91_91id_93_93jc0dwHiBchMeta } from "/tmp/build_a226154c/pages/dashboard/places/[[id]].vue?macro=true";
import { default as indexJKkq9UmMQjMeta } from "/tmp/build_a226154c/pages/dashboard/users/index.vue?macro=true";
import { default as indexTxz6B9KlTnMeta } from "/tmp/build_a226154c/pages/index.vue?macro=true";
import { default as imprinthHI216Veh2Meta } from "/tmp/build_a226154c/pages/legal/imprint.vue?macro=true";
import { default as privacy_45policyRxg4ypZmKeMeta } from "/tmp/build_a226154c/pages/legal/privacy-policy.vue?macro=true";
import { default as terms_45of_45service7qc9BrE0JNMeta } from "/tmp/build_a226154c/pages/legal/terms-of-service.vue?macro=true";
import { default as indexiOijHbqqf6Meta } from "/tmp/build_a226154c/pages/map/feed/index.vue?macro=true";
import { default as _91highlightUrlName_936VpoYlN22WMeta } from "/tmp/build_a226154c/pages/map/highlight/[highlightUrlName].vue?macro=true";
import { default as index69hVg4oztJMeta } from "/tmp/build_a226154c/pages/map/index.vue?macro=true";
import { default as _91_91slug_93_93jD2wMMupdfMeta } from "/tmp/build_a226154c/pages/map/list/[[nanoId]]/[[slug]].vue?macro=true";
import { default as _91_91slug_93_938gRFF5IvidMeta } from "/tmp/build_a226154c/pages/map/place/[nanoId]/[[slug]].vue?macro=true";
import { default as support3xPRFJXcaKMeta } from "/tmp/build_a226154c/pages/support.vue?macro=true";
import { default as _91_91slug_93_93pk5RlAhrbZMeta } from "/tmp/build_a226154c/pages/test/[nanoId]/[[slug]].vue?macro=true";
import { default as indexeRiLFK4EGwMeta } from "/tmp/build_a226154c/pages/zemma/index.vue?macro=true";
export default [
  {
    name: cancel5g99FWqUSVMeta?.name ?? "auth-checkout-cancel___en",
    path: cancel5g99FWqUSVMeta?.path ?? "/auth/checkout/cancel",
    meta: cancel5g99FWqUSVMeta || {},
    alias: cancel5g99FWqUSVMeta?.alias || [],
    redirect: cancel5g99FWqUSVMeta?.redirect,
    component: () => import("/tmp/build_a226154c/pages/auth/checkout/cancel.vue").then(m => m.default || m)
  },
  {
    name: choose_45productWj5pfi2XmcMeta?.name ?? "auth-checkout-choose-product___en",
    path: choose_45productWj5pfi2XmcMeta?.path ?? "/auth/checkout/choose-product",
    meta: choose_45productWj5pfi2XmcMeta || {},
    alias: choose_45productWj5pfi2XmcMeta?.alias || [],
    redirect: choose_45productWj5pfi2XmcMeta?.redirect,
    component: () => import("/tmp/build_a226154c/pages/auth/checkout/choose-product.vue").then(m => m.default || m)
  },
  {
    name: success_45quo_45proDXktB1MEW9Meta?.name ?? "auth-checkout-success-quo-pro___en",
    path: success_45quo_45proDXktB1MEW9Meta?.path ?? "/auth/checkout/success-quo-pro",
    meta: success_45quo_45proDXktB1MEW9Meta || {},
    alias: success_45quo_45proDXktB1MEW9Meta?.alias || [],
    redirect: success_45quo_45proDXktB1MEW9Meta?.redirect,
    component: () => import("/tmp/build_a226154c/pages/auth/checkout/success-quo-pro.vue").then(m => m.default || m)
  },
  {
    name: successo1ed61oyjWMeta?.name ?? "auth-checkout-success___en",
    path: successo1ed61oyjWMeta?.path ?? "/auth/checkout/success",
    meta: successo1ed61oyjWMeta || {},
    alias: successo1ed61oyjWMeta?.alias || [],
    redirect: successo1ed61oyjWMeta?.redirect,
    component: () => import("/tmp/build_a226154c/pages/auth/checkout/success.vue").then(m => m.default || m)
  },
  {
    name: share_45pro_45successsfjizDfKOiMeta?.name ?? "auth-invite-share-pro-success___en",
    path: share_45pro_45successsfjizDfKOiMeta?.path ?? "/auth/invite/share-pro-success",
    meta: share_45pro_45successsfjizDfKOiMeta || {},
    alias: share_45pro_45successsfjizDfKOiMeta?.alias || [],
    redirect: share_45pro_45successsfjizDfKOiMeta?.redirect,
    component: () => import("/tmp/build_a226154c/pages/auth/invite/share-pro-success.vue").then(m => m.default || m)
  },
  {
    name: share_45proy4UcNCuGQ7Meta?.name ?? "auth-invite-share-pro___en",
    path: share_45proy4UcNCuGQ7Meta?.path ?? "/auth/invite/share-pro",
    meta: share_45proy4UcNCuGQ7Meta || {},
    alias: share_45proy4UcNCuGQ7Meta?.alias || [],
    redirect: share_45proy4UcNCuGQ7Meta?.redirect,
    component: () => import("/tmp/build_a226154c/pages/auth/invite/share-pro.vue").then(m => m.default || m)
  },
  {
    name: confirm_45emailHoRFiSCl0aMeta?.name ?? "auth-register-confirm-email___en",
    path: confirm_45emailHoRFiSCl0aMeta?.path ?? "/auth/register/confirm-email",
    meta: confirm_45emailHoRFiSCl0aMeta || {},
    alias: confirm_45emailHoRFiSCl0aMeta?.alias || [],
    redirect: confirm_45emailHoRFiSCl0aMeta?.redirect,
    component: () => import("/tmp/build_a226154c/pages/auth/register/confirm-email.vue").then(m => m.default || m)
  },
  {
    name: create_45profileNMabNri1hTMeta?.name ?? "auth-register-create-profile___en",
    path: create_45profileNMabNri1hTMeta?.path ?? "/auth/register/create-profile",
    meta: create_45profileNMabNri1hTMeta || {},
    alias: create_45profileNMabNri1hTMeta?.alias || [],
    redirect: create_45profileNMabNri1hTMeta?.redirect,
    component: () => import("/tmp/build_a226154c/pages/auth/register/create-profile.vue").then(m => m.default || m)
  },
  {
    name: forward_45urlGQ9LObp9kgMeta?.name ?? "auth-register-forward-url___en",
    path: forward_45urlGQ9LObp9kgMeta?.path ?? "/auth/register/forward-url",
    meta: forward_45urlGQ9LObp9kgMeta || {},
    alias: forward_45urlGQ9LObp9kgMeta?.alias || [],
    redirect: forward_45urlGQ9LObp9kgMeta?.redirect,
    component: () => import("/tmp/build_a226154c/pages/auth/register/forward-url.vue").then(m => m.default || m)
  },
  {
    name: indexRQn7jzDG7eMeta?.name ?? "auth-register___en",
    path: indexRQn7jzDG7eMeta?.path ?? "/auth/register",
    meta: indexRQn7jzDG7eMeta || {},
    alias: indexRQn7jzDG7eMeta?.alias || [],
    redirect: indexRQn7jzDG7eMeta?.redirect,
    component: () => import("/tmp/build_a226154c/pages/auth/register/index.vue").then(m => m.default || m)
  },
  {
    name: indexvhs8yqwJ2lMeta?.name ?? "auth-sign-in___en",
    path: indexvhs8yqwJ2lMeta?.path ?? "/auth/sign-in",
    meta: indexvhs8yqwJ2lMeta || {},
    alias: indexvhs8yqwJ2lMeta?.alias || [],
    redirect: indexvhs8yqwJ2lMeta?.redirect,
    component: () => import("/tmp/build_a226154c/pages/auth/sign-in/index.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordhZ0RXXpjFoMeta?.name ?? "auth-sign-in-reset-password___en",
    path: reset_45passwordhZ0RXXpjFoMeta?.path ?? "/auth/sign-in/reset-password",
    meta: reset_45passwordhZ0RXXpjFoMeta || {},
    alias: reset_45passwordhZ0RXXpjFoMeta?.alias || [],
    redirect: reset_45passwordhZ0RXXpjFoMeta?.redirect,
    component: () => import("/tmp/build_a226154c/pages/auth/sign-in/reset-password.vue").then(m => m.default || m)
  },
  {
    name: send_45password_45link7hiNZmY6vfMeta?.name ?? "auth-sign-in-send-password-link___en",
    path: send_45password_45link7hiNZmY6vfMeta?.path ?? "/auth/sign-in/send-password-link",
    meta: send_45password_45link7hiNZmY6vfMeta || {},
    alias: send_45password_45link7hiNZmY6vfMeta?.alias || [],
    redirect: send_45password_45link7hiNZmY6vfMeta?.redirect,
    component: () => import("/tmp/build_a226154c/pages/auth/sign-in/send-password-link.vue").then(m => m.default || m)
  },
  {
    name: teamm49VGZKBRLMeta?.name ?? "company-team___en",
    path: teamm49VGZKBRLMeta?.path ?? "/company/team",
    meta: teamm49VGZKBRLMeta || {},
    alias: teamm49VGZKBRLMeta?.alias || [],
    redirect: teamm49VGZKBRLMeta?.redirect,
    component: () => import("/tmp/build_a226154c/pages/company/team.vue").then(m => m.default || m)
  },
  {
    name: confirmN1a3UrtS65Meta?.name ?? "confirm___en",
    path: confirmN1a3UrtS65Meta?.path ?? "/confirm",
    meta: confirmN1a3UrtS65Meta || {},
    alias: confirmN1a3UrtS65Meta?.alias || [],
    redirect: confirmN1a3UrtS65Meta?.redirect,
    component: () => import("/tmp/build_a226154c/pages/confirm.vue").then(m => m.default || m)
  },
  {
    name: index2E78Yvdw7qMeta?.name ?? "dashboard-helper-countries___en",
    path: index2E78Yvdw7qMeta?.path ?? "/dashboard/helper/countries",
    meta: index2E78Yvdw7qMeta || {},
    alias: index2E78Yvdw7qMeta?.alias || [],
    redirect: index2E78Yvdw7qMeta?.redirect,
    component: () => import("/tmp/build_a226154c/pages/dashboard/helper/countries/index.vue").then(m => m.default || m)
  },
  {
    name: _91_91id_93_93M7snwPiqULMeta?.name ?? "dashboard-helper-tags-id___en",
    path: _91_91id_93_93M7snwPiqULMeta?.path ?? "/dashboard/helper/tags/:id?",
    meta: _91_91id_93_93M7snwPiqULMeta || {},
    alias: _91_91id_93_93M7snwPiqULMeta?.alias || [],
    redirect: _91_91id_93_93M7snwPiqULMeta?.redirect,
    component: () => import("/tmp/build_a226154c/pages/dashboard/helper/tags/[[id]].vue").then(m => m.default || m)
  },
  {
    name: indexGIMyNz4NkeMeta?.name ?? "dashboard___en",
    path: indexGIMyNz4NkeMeta?.path ?? "/dashboard",
    meta: indexGIMyNz4NkeMeta || {},
    alias: indexGIMyNz4NkeMeta?.alias || [],
    redirect: indexGIMyNz4NkeMeta?.redirect,
    component: () => import("/tmp/build_a226154c/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: _91_91id_93_93jc0dwHiBchMeta?.name ?? "dashboard-places-id___en",
    path: _91_91id_93_93jc0dwHiBchMeta?.path ?? "/dashboard/places/:id?",
    meta: _91_91id_93_93jc0dwHiBchMeta || {},
    alias: _91_91id_93_93jc0dwHiBchMeta?.alias || [],
    redirect: _91_91id_93_93jc0dwHiBchMeta?.redirect,
    component: () => import("/tmp/build_a226154c/pages/dashboard/places/[[id]].vue").then(m => m.default || m)
  },
  {
    name: indexJKkq9UmMQjMeta?.name ?? "dashboard-users___en",
    path: indexJKkq9UmMQjMeta?.path ?? "/dashboard/users",
    meta: indexJKkq9UmMQjMeta || {},
    alias: indexJKkq9UmMQjMeta?.alias || [],
    redirect: indexJKkq9UmMQjMeta?.redirect,
    component: () => import("/tmp/build_a226154c/pages/dashboard/users/index.vue").then(m => m.default || m)
  },
  {
    name: indexTxz6B9KlTnMeta?.name ?? "index___en",
    path: indexTxz6B9KlTnMeta?.path ?? "/",
    meta: indexTxz6B9KlTnMeta || {},
    alias: indexTxz6B9KlTnMeta?.alias || [],
    redirect: indexTxz6B9KlTnMeta?.redirect,
    component: () => import("/tmp/build_a226154c/pages/index.vue").then(m => m.default || m)
  },
  {
    name: imprinthHI216Veh2Meta?.name ?? "legal-imprint___en",
    path: imprinthHI216Veh2Meta?.path ?? "/legal/imprint",
    meta: imprinthHI216Veh2Meta || {},
    alias: imprinthHI216Veh2Meta?.alias || [],
    redirect: imprinthHI216Veh2Meta?.redirect,
    component: () => import("/tmp/build_a226154c/pages/legal/imprint.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyRxg4ypZmKeMeta?.name ?? "legal-privacy-policy___en",
    path: privacy_45policyRxg4ypZmKeMeta?.path ?? "/legal/privacy-policy",
    meta: privacy_45policyRxg4ypZmKeMeta || {},
    alias: privacy_45policyRxg4ypZmKeMeta?.alias || [],
    redirect: privacy_45policyRxg4ypZmKeMeta?.redirect,
    component: () => import("/tmp/build_a226154c/pages/legal/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: terms_45of_45service7qc9BrE0JNMeta?.name ?? "legal-terms-of-service___en",
    path: terms_45of_45service7qc9BrE0JNMeta?.path ?? "/legal/terms-of-service",
    meta: terms_45of_45service7qc9BrE0JNMeta || {},
    alias: terms_45of_45service7qc9BrE0JNMeta?.alias || [],
    redirect: terms_45of_45service7qc9BrE0JNMeta?.redirect,
    component: () => import("/tmp/build_a226154c/pages/legal/terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: indexiOijHbqqf6Meta?.name ?? "map-feed___en",
    path: indexiOijHbqqf6Meta?.path ?? "/map/feed",
    meta: indexiOijHbqqf6Meta || {},
    alias: indexiOijHbqqf6Meta?.alias || [],
    redirect: indexiOijHbqqf6Meta?.redirect,
    component: () => import("/tmp/build_a226154c/pages/map/feed/index.vue").then(m => m.default || m)
  },
  {
    name: _91highlightUrlName_936VpoYlN22WMeta?.name ?? "map-highlight-highlightUrlName___en",
    path: _91highlightUrlName_936VpoYlN22WMeta?.path ?? "/map/highlight/:highlightUrlName()",
    meta: _91highlightUrlName_936VpoYlN22WMeta || {},
    alias: _91highlightUrlName_936VpoYlN22WMeta?.alias || [],
    redirect: _91highlightUrlName_936VpoYlN22WMeta?.redirect,
    component: () => import("/tmp/build_a226154c/pages/map/highlight/[highlightUrlName].vue").then(m => m.default || m)
  },
  {
    name: index69hVg4oztJMeta?.name ?? "map___en",
    path: index69hVg4oztJMeta?.path ?? "/map",
    meta: index69hVg4oztJMeta || {},
    alias: index69hVg4oztJMeta?.alias || [],
    redirect: index69hVg4oztJMeta?.redirect,
    component: () => import("/tmp/build_a226154c/pages/map/index.vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_93jD2wMMupdfMeta?.name ?? "map-list-nanoId-slug___en",
    path: _91_91slug_93_93jD2wMMupdfMeta?.path ?? "/map/list/:nanoId?/:slug?",
    meta: _91_91slug_93_93jD2wMMupdfMeta || {},
    alias: _91_91slug_93_93jD2wMMupdfMeta?.alias || [],
    redirect: _91_91slug_93_93jD2wMMupdfMeta?.redirect,
    component: () => import("/tmp/build_a226154c/pages/map/list/[[nanoId]]/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_938gRFF5IvidMeta?.name ?? "map-place-nanoId-slug___en",
    path: _91_91slug_93_938gRFF5IvidMeta?.path ?? "/map/place/:nanoId()/:slug?",
    meta: _91_91slug_93_938gRFF5IvidMeta || {},
    alias: _91_91slug_93_938gRFF5IvidMeta?.alias || [],
    redirect: _91_91slug_93_938gRFF5IvidMeta?.redirect,
    component: () => import("/tmp/build_a226154c/pages/map/place/[nanoId]/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: support3xPRFJXcaKMeta?.name ?? "support___en",
    path: support3xPRFJXcaKMeta?.path ?? "/support",
    meta: support3xPRFJXcaKMeta || {},
    alias: support3xPRFJXcaKMeta?.alias || [],
    redirect: support3xPRFJXcaKMeta?.redirect,
    component: () => import("/tmp/build_a226154c/pages/support.vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_93pk5RlAhrbZMeta?.name ?? "test-nanoId-slug___en",
    path: _91_91slug_93_93pk5RlAhrbZMeta?.path ?? "/test/:nanoId()/:slug?",
    meta: _91_91slug_93_93pk5RlAhrbZMeta || {},
    alias: _91_91slug_93_93pk5RlAhrbZMeta?.alias || [],
    redirect: _91_91slug_93_93pk5RlAhrbZMeta?.redirect,
    component: () => import("/tmp/build_a226154c/pages/test/[nanoId]/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: indexeRiLFK4EGwMeta?.name ?? "zemma___en",
    path: indexeRiLFK4EGwMeta?.path ?? "/zemma",
    meta: indexeRiLFK4EGwMeta || {},
    alias: indexeRiLFK4EGwMeta?.alias || [],
    redirect: indexeRiLFK4EGwMeta?.redirect,
    component: () => import("/tmp/build_a226154c/pages/zemma/index.vue").then(m => m.default || m)
  }
]