import { useMetricStore } from '~/stores/metric'

export default defineNuxtPlugin((nuxtApp) => {
  return {
    provide: {
      metric: async (metric) => {
        const metricStore = useMetricStore(nuxtApp.$pinia)
        await metricStore.createMetric(metric)
      },
    },
  }
})
