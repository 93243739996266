import { useAppStore } from '~/stores/app'
import { useMetricStore } from '~/stores/metric'

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook('app:created', async () => {
    const { $log } = nuxtApp
    $log('plugins:appCreated')
    const appStore = useAppStore(nuxtApp.$pinia)
    const { deviceId } = storeToRefs(appStore)
    appStore.agent = navigator.userAgent

    const metricStore = useMetricStore(nuxtApp.$pinia)

    const metric = {
      key: 'APP_START',
      referrer: document.referrer,
      quoVersion: appStore.version,
      quoPlattform: 'web',
      plattformLanguage: navigator.language,
      windowSize: window.innerWidth + 'x' + window.innerHeight,
      userAgent: appStore.agent,
      isDesktop: nuxtApp.$device.isDesktop,
      isMobile: nuxtApp.$device.isMobile,
      isTablet: nuxtApp.$device.isTablet,
      isIos: nuxtApp.$device.isIos,
      isWindows: nuxtApp.$device.isWindows,
      isMacOS: nuxtApp.$device.isMacOS,
      isAndroid: nuxtApp.$device.isAndroid,
      isFirefox: nuxtApp.$device.isFirefox,
      isEdge: nuxtApp.$device.isEdge,
      isChrome: nuxtApp.$device.isChrome,
      isSafari: nuxtApp.$device.isSafari,
      isCrawler: nuxtApp.$device.isCrawler,
      deviceId: deviceId.value,
    }
    await metricStore.createMetric(metric)
  })
})
