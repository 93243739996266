import revive_payload_client_4sVQNw7RlN from "/tmp/build_a226154c/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/tmp/build_a226154c/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/tmp/build_a226154c/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import supabase_client_I4Hv4qpJHd from "/tmp/build_a226154c/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.mjs";
import payload_client_yVLowv6hDl from "/tmp/build_a226154c/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/tmp/build_a226154c/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/tmp/build_a226154c/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/tmp/build_a226154c/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/tmp/build_a226154c/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import i18n_yfWm7jX06p from "/tmp/build_a226154c/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_t2GMTTFnMT from "/tmp/build_a226154c/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import plugin_client_i8AMfKeYnY from "/tmp/build_a226154c/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import chunk_reload_client_UciE0i6zes from "/tmp/build_a226154c/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_1UohGbtF8v from "/tmp/build_a226154c/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import _01_clog_xjpMmro3WI from "/tmp/build_a226154c/plugins/01.clog.js";
import _02_syncSupabaseUser_ybwPEDOnNr from "/tmp/build_a226154c/plugins/02.syncSupabaseUser.js";
import _03_metric_h1riBab2Ae from "/tmp/build_a226154c/plugins/03.metric.js";
import apexcharts_client_xo3MRJYPBX from "/tmp/build_a226154c/plugins/apexcharts.client.js";
import appCreated_client_y1jWunP2gF from "/tmp/build_a226154c/plugins/appCreated.client.js";
import appMounted_Ydkfpy6TtK from "/tmp/build_a226154c/plugins/appMounted.js";
import sentry_client_shVUlIjFLk from "/tmp/build_a226154c/plugins/sentry.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  supabase_client_I4Hv4qpJHd,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  i18n_yfWm7jX06p,
  plugin_t2GMTTFnMT,
  plugin_client_i8AMfKeYnY,
  chunk_reload_client_UciE0i6zes,
  plugin_1UohGbtF8v,
  _01_clog_xjpMmro3WI,
  _02_syncSupabaseUser_ybwPEDOnNr,
  _03_metric_h1riBab2Ae,
  apexcharts_client_xo3MRJYPBX,
  appCreated_client_y1jWunP2gF,
  appMounted_Ydkfpy6TtK,
  sentry_client_shVUlIjFLk
]