import { format } from 'date-fns'

export default defineNuxtPlugin(() => {
  return {
    provide: {
      log: (...logs) => {
        if (process.env.NODE_ENV === 'production') return
        const date = format(new Date(), 'HH:mm:ss:SSS')
        console.log(date, ...logs)
      },
    },
  }
})
