<template>
  <main class="relative isolate min-h-full">
    <img
      src="/images/into-void.jpeg"
      alt=""
      class="absolute inset-0 -z-10 h-full w-full object-cover object-top"
    />
    <div class="mx-auto max-w-7xl px-6 py-32 text-center sm:py-40 lg:px-8">
      <p class="text-base font-semibold leading-8 text-white">
        {{ props.error.statusCode }}
      </p>
      <h1 class="mt-4 text-3xl font-bold tracking-tight text-white sm:text-5xl">
        {{ props.error.statusMessage }}
      </h1>
      <p class="mt-4 text-base text-white/70 sm:mt-6">
        {{ props.error.message }}
      </p>
      <div class="mt-10 flex justify-center">
        <NuxtLink to="/" class="text-sm font-semibold leading-7 text-white"
          ><span aria-hidden="true">&larr;</span> Back to home</NuxtLink
        >
      </div>
    </div>
  </main>
</template>
<script setup>
useHead({
  bodyAttrs: {
    class: 'h-full',
  },
  htmlAttrs: {
    class: 'h-full',
  },
})
const props = defineProps({
  error: Object,
})
const { $metric } = useNuxtApp()
$metric({ key: 'ERROR', value: props.error })
</script>
<style>
#__nuxt {
  @apply h-full;
}
</style>
